import DocumentMeta from "react-document-meta";
import { meta } from "../../utils/Info";
import { consultancy } from "../../utils/InfoProduct";
import Section from "./components/Section";
import CardOption from "./components/CardOption";
import Schedule from "../../components/Schedule";

export default function Consultancy() {
    return (
        <div className='w-full max-w-screen-2xl mx-auto flex flex-col justify-center items-center overflow-hidden px-5 lg:px-10'>
            <DocumentMeta {...meta} />
            <Section text={consultancy.section} />
            <img src={`/icons/${consultancy.title}.svg`} alt={consultancy.title} className="pt-16 lg:pt-5 pb-10" />
            <div className="w-full lg:w-6/12 tracking-normal text-lg leading-tight space-y-10">
                <p className="font-bold text-center">{consultancy.subtitle}</p>
                <p className="text-center text-sm">{consultancy.description}</p>
            </div>
            <div className="w-full relative mt-16 mb-32 lg:my-24">
                <div className="w-80 h-16 relative z-10 mx-auto flex justify-between items-end">
                    <div className="bg-green-gradient90 w-full h-full absolute top-2 left-2 mx-auto rounded-xl"></div>
                    <img src="/gif/alerta3.gif" alt="alerta3" className="relative z-10 w-20 top-2 left-5" />
                    <div className="w-52 h-full bg-white border border-black font-semibold rounded-xl p-5 relative z-10 flex justify-end items-center text-xs gap-3">
                        <img src="/icons/cup.svg" alt="cup" className="w-5" />
                        <p className="mt-1">Mis consultas</p>
                    </div>
                </div>
                <img src="/icons/separator.svg" alt="separator" className="w-full absolute top-1/2 left-1/2 -translate-x-1/2" />
            </div>
            <div className="w-full pb-10 space-y-32 lg:space-y-16">
                {
                    consultancy.options.map(option => <CardOption option={option} product={consultancy.title} />)
                }
            </div>
            <Schedule />
        </div>
    )
}