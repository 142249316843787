import SkeletonSection from "./SkeletonSection";

export default function OptionCard({ imgSrc, imgAlt, titleWidth, iconWidth }: { imgSrc: string; imgAlt: string; titleWidth: string, iconWidth: string }) {
    return (
        <div className="w-full max-w-[257px] h-[188px] border border-skeleton shadow-skeleton rounded-2xl overflow-hidden p-3">
            <div className="w-full flex justify-between items-center">
                <img src={imgSrc} alt={imgAlt} className={`w-${iconWidth}`} />
                <div className={`bg-skeleton-light w-${titleWidth} h-6 rounded-md`}></div>
            </div>
            <div className="border border-skeleton w-full h-1 rounded-full my-4"></div>
            <div className="space-y-1">
                <SkeletonSection width="full" />
                <SkeletonSection width="full" />
                <SkeletonSection width="2/3" />
            </div>
            <div className="bg-skeleton-light w-1/2 mx-auto h-7 rounded-lg mt-7 mb-3"></div>
        </div>
    )
};