import { historySection } from '../../../utils/Info';
import HistoryCard from '../../../components/HistoryCard';


export default function HistorySection() {
    return (
        <section className='w-full px-5 lg:px-10'>
            <div className='w-full flex flex-col justify-start items-center pt-10 pb-0 lg:pb-10 gap-10'>
                <div className='flex flex-col justify-center items-center gap-10'>
                    <h2 className='font-bold text-2xl lg:text-3xl tracking-tighter whitespace-pre-line leading-tight lg:leading-snug text-center'>{historySection.title}</h2>
                    <div className='w-5/6 lg:w-3/5 text-center tracking-tighter text-sm lg:text-base'>
                        <p>{historySection.description}</p>
                    </div>
                </div>
                <div className='hidden xl:w-2/3 lg:w-4/5 lg:flex flex-col lg:flex-row justify-between items-center gap-16 h-[400px] relative'>
                    <HistoryCard item={historySection.historyLeft} position={'left'} />
                    <HistoryCard item={historySection.historyRight} position={'right'} />
                    <img src="/images/bg-history.png" alt="bg-history" className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 lg:w-full xl:w-4/5' />
                </div>

                <div className='lg:hidden w-full flex flex-col lg:flex-row justify-center items-center gap-16 relative mb-10'>
                    <div className='absolute flex flex-col lg:flex-row justify-center items-center gap-28 top-16 z-10'>
                        <div className='w-full md:w-2/3 lg:w-full flex justify-end'>
                            <HistoryCard item={historySection.historyLeft} position={'left'} />
                        </div>
                        <div className='w-full md:w-2/3 lg:w-full flex justify-start'>
                            <HistoryCard item={historySection.historyRight} position={'right'} />
                        </div>
                    </div>
                    {/* <img src="/images/bg-historyMobile.png" alt="bg-history" className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-11/12' /> */}
                    <img src="/images/bg-historyMobile.png" alt="bg-history" className='relative h-[800px] md:h-[700px]' />
                </div>
            </div>
        </section>
    )
}