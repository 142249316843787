import { useLocation } from 'react-router-dom';
import { footer } from '../utils/Info';
import ButtonWhite from './ButtonWhite';
import FooterList from './FooterList';


function Footer() {
    const location = useLocation();
    const { pathname } = location;
    // const withoutHeader = ["/mobile/custom"];
    return (
        !pathname.includes("/mobile/customer") ?
            <div className='w-full flex flex-col justify-center items-center z-10 px-5 lg:px-10 pb-16'>
                <div className='w-full pb-10 lg:pb-5'>
                    <hr className='w-full border-black' />
                </div>
                <div className='w-full border-05 border-black bg-white bg-opacity-60 rounded-xl pt-5 px-5 pb-10 lg:px-10 lg:py-10 tracking-tighter grid grid-cols-6'>
                    <div className='hidden xl:block col-span-6 xl:col-span-1 order-1 xl:order-1'>
                        <img src={`/icons/${footer.logo}.svg`} alt={footer.logo} className='w-44 mx-auto md:mx-0 ml-0 md:ml-9 my-7 lg:m-0' />
                    </div>
                    <div className='col-span-6 xl:col-span-4 grid grid-cols-2 md:grid-cols-4 gap-x-5 gap-y-10 lg:gap-10 px-0 lg:px-10 text-sm order-3 xl:order-2'>
                        <div className='col-span-1 order-2 xl:order-1'>
                            <FooterList title={footer.solutions.title} options={footer.solutions.options} />
                        </div>
                        <div className='col-span-1 order-4 xl:order-2'>
                            <FooterList title={footer.features.title} options={footer.features.options} />
                        </div>
                        <div className='col-span-1 space-y-5 order-3 xl:order-3'>
                            <FooterList title={footer.security.title} options={footer.security.options} />
                            <div className='hidden xl:block'>
                                <FooterList title={footer.price.title} options={footer.price.options} />
                            </div>
                        </div>
                        <div className='block xl:hidden col-span-1 space-y-5 order-4'>
                            <FooterList title={footer.price.title} options={footer.price.options} />
                        </div>
                        <div className='hidden xl:block col-span-2 lg:col-span-1 space-y-5 order-1 xl:order-4'>
                            <p className="font-bold text-left">{footer.download.title}</p>
                            <div className='flex flex-row lg:flex-col justify-between md:justify-center items-start gap-5'>
                                {
                                    footer.download.options.map((option: any) =>
                                        <a key={option.title} href={option.link} className='w-40 px-2 lg:px-3 py-2 border border-black rounded-xl flex justify-start items-center gap-2 lg:gap-3 bg-white'>
                                            <img src={`/images/${option.image}.png`} alt={option.image} className='w-6' />
                                            <div>
                                                <p className='text-[9px] lg:text-xs'>{option.text}</p>
                                                <p className='font-bold text-xs'>{option.title}</p>
                                            </div>
                                        </a>)
                                }
                            </div>
                        </div>
                    </div>
                    <div className='col-span-6 xl:col-span-1 order-2 xl:order-3'>
                        <div className='flex flex-col md:flex-row xl:flex-col justify-start md:justify-between xl:justify-start items-center md:items-start xl:items-center gap-7 lg:gap-3 mb-5 mt-2 lg:m-0'>
                            <div className='block xl:hidden'>
                                <img src={`/icons/${footer.logo}.svg`} alt={footer.logo} className='w-44' />
                            </div>
                            <div className='space-y-5'>
                                <div className='flex justify-center items-center gap-6'>
                                    {
                                        footer.socials.map(social =>
                                            <a key={social.image} href={social.link}>
                                                <img src={`/images/${social.image}.png`} alt={social.image} className='w-5' />
                                            </a>
                                        )
                                    }
                                </div>
                                <a className='block w-36' href={footer.buttonLink}>
                                    <ButtonWhite title={footer.button} />
                                </a>
                            </div>
                            <div className='block xl:hidden space-y-5'>
                                <p className="font-bold text-left">{footer.download.title}</p>
                                <div className='flex flex-row xl:flex-col justify-between md:justify-center items-center gap-5'>
                                    {
                                        footer.download.options.map((option: any) =>
                                            <a key={option.title} href={option.link} className='w-36 px-2 xl:px-3 py-2 border border-black rounded-xl flex justify-start items-center gap-2 xl:gap-3 bg-white'>
                                                <img src={`/images/${option.image}.png`} alt={option.image} className='w-6' />
                                                <div>
                                                    <p className='text-[9px] xl:text-xs'>{option.text}</p>
                                                    <p className='font-bold text-xs'>{option.title}</p>
                                                </div>
                                            </a>)
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='block xl:hidden w-full px-5 lg:px-10 pt-5 pb-10'>
                            <hr className='w-full border-black' />
                        </div>
                    </div>
                </div>
            </div >
            : null
    );
}

export default Footer;