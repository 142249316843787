import { shceduleSection } from "../utils/Info";
import ButtonWhite from "./ButtonWhite";

export default function Schedule() {
    return (
        <div className="w-full bg-green-gradient180-op py-10 px-5 lg:px-20 rounded-2xl flex justify-center items-center mb-7">
            <div className="w-full lg:w-3/4 flex flex-col lg:flex-row justify-between items-center">
                <div className="w-full lg:w-3/5 mb-5 lg:mb-0">
                    <h3 className='font-bold text-2xl lg:text-3xl tracking-tighter whitespace-pre-line leading-tight lg:leading-snug text-center lg:text-left mb-5'>{shceduleSection.title}</h3>
                    <div className='text-center lg:text-left font-light tracking-tighter text-sm lg:text-base'>
                        <p>{shceduleSection.text}</p>
                    </div>
                </div>
                <a className='w-60 block mt-10 lg:mt-0' href={shceduleSection.buttonLink}>
                    <ButtonWhite title={shceduleSection.button} />
                </a>
            </div>
        </div>
    )
}