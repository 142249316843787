import { consultancySection } from '../../../utils/Info';
import ButtonBlackBR from '../../../components/ButtonBlackBR';
import Button from '../../../components/Button';
import SectionHeader from '../../../components/SectionHeader';
import { useEffect, useRef } from 'react';


export default function ConsultancySection() {
    const phoneSection = useRef<HTMLDivElement | null>(null);
    const text_one = useRef<HTMLDivElement | null>(null);
    const text_two = useRef<HTMLDivElement | null>(null);
    const text_three = useRef<HTMLDivElement | null>(null);
    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    console.log('entry.isIntersecting', entry.isIntersecting)
                    console.log('entry.intersectionRatio', entry.intersectionRatio)
                    if (entry.intersectionRatio >= 0.5) {
                        text_three.current?.classList.add("animate-consult-slide-left");
                        text_three.current?.classList.remove("hidden");
                    }
                    if (entry.intersectionRatio >= 0.3) {
                        text_two.current?.classList.add("animate-consult-slide-left");
                        text_two.current?.classList.remove("hidden");
                    } 
                    if (entry.intersectionRatio >= 0.1) {
                        text_one.current?.classList.add("animate-consult-slide-left");
                        text_one.current?.classList.remove("hidden");
                    }
                });
            },
            {
                threshold: [0.1, 0.3, 0.5], // Se activan en los umbrales 30% y 50%
            }
            // { threshold: 0.1 } // Se activa cuando el 50% del elemento es visible
        );

        if (phoneSection.current) {
            observer.observe(phoneSection.current);
        }

        return () => {
            if (phoneSection.current) {
                observer.unobserve(phoneSection.current);
            }
        };
    }, [])
    return (
        <section ref={phoneSection} className='w-full px-5 lg:px-10'>
            <div className='w-full lg:w-1/2 pt-16 lg:pt-0'>
                <SectionHeader sectionName={consultancySection.sectionName} sectionDescription={consultancySection.sectionDescription} />
            </div>
            <div className='w-full grid grid-cols-12 pb-10'>
                <div className='col-span-12 md:col-span-6 order-2 md:order-1 flex flex-col justify-center items-center gap-10 py-5 lg:py-0'>
                    <h2 className='font-bold text-2xl lg:text-3xl tracking-tighter whitespace-pre-line leading-tight lg:leading-snug text-center'>{consultancySection.title}</h2>
                    <div className='w-5/6 lg:w-full text-center font-light tracking-tighter text-sm lg:text-base'>
                        <p>{consultancySection.description}</p>
                    </div>
                    <div className='w-full relative py-10 mb-10 flex justify-center items-center'>
                        <img src="/images/consultType.png" alt="consultType" className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[60%] mt-2 min-w-[400px]' />
                        <div className='w-auto flex justify-center items-center gap-5 p-5 border border-solid border-black rounded-2xl bg-black'>
                            {
                                consultancySection.options.map(item =>
                                    <div key={item} className='bg-green-gradient180 rounded-full p-[2px]'>
                                        <div key={item} className='flex flex-col justify-center items-center text-xs lg:text-base font-semibold lg:font-normal gap-3 lg:gap-0 border border-black w-10 h-10 rounded-full bg-white'>
                                            <img src={`/icons/${item}.svg`} alt={item} className='w-5 h-5' />
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                    <div className='w-full flex flex-col md:flex-row justify-center items-center gap-5 lg:gap-20'>
                        <a className='w-48 block' href={consultancySection.buttonLeftLink}>
                            <Button title={consultancySection.buttonLeft} />
                        </a>
                        <a className='w-48 block' href={consultancySection.buttonRightLink}>
                            <ButtonBlackBR title={consultancySection.buttonRight} />
                        </a>
                    </div>
                </div>
                <div className='col-span-12 md:col-span-6 order-1 md:order-2 flex justify-center items-center relative tracking-tighter py-10 lg:py-0'>
                    <img src="/images/phone.png" alt="phone" className='hidden lg:block w-1/2' />
                    <img src="/images/phoneMobile.png" alt="phoneMobile" className='block lg:hidden w-2/3' />
                    {/* <p ref={elementRef} className='bg-white border border-black p-3 xl:p-5 text-sm xl:text-base rounded-xl absolute right-0 '>{consultancySection.phoneCard[0]}</p> */}
                    <p ref={text_one} className='bg-white border border-black p-3 xl:p-5 text-sm xl:text-base rounded-xl absolute left-[45%] lg:left-[60%] hidden'>{consultancySection.phoneCard[0]}</p>
                    <p ref={text_two} className='bg-white border border-black p-3 xl:p-5 text-sm xl:text-base rounded-xl absolute top-[60%] left-0'>{consultancySection.phoneCard[1]}</p>
                    <p ref={text_three} className='bg-white border border-black p-3 xl:p-5 text-sm xl:text-base rounded-xl absolute top-[75%] left-[20%] lg:left-[50%] xl:left-[55%] hidden'>{consultancySection.phoneCard[2]}</p>
                </div>
            </div>
        </section>
    )
}