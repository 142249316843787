import ButtonGradientBR from "../../../components/ButtonGradientBR";
import AttachCard from "./AttachCard";
import CardChat from "./CardChat";
import CardOffer from "./CardOffer";
import DocumentTypes from "./DocumentTypes";
import OptionCard from "./OptionCard";
import SkeletonSection from "./SkeletonSection";

interface OptionTypeProps {
    type: 'sri' | 'light' | 'calendar' | 'policy' | 'create' | 'choose' | 'answer' | 'document' | 'upload' | 'check';
    product: 'monitor' | 'consultancy' | 'documents';
}

const OptionType = ({ type, product }: OptionTypeProps) => {
    return (
        <>
            {product === 'monitor' && (
                <>
                    {type === 'sri' && (
                        <div className="col-span-2 flex flex-col justify-center items-end pl-5 lg:pl-0">
                            <OptionCard imgSrc="/images/logo-sri.png" imgAlt="logo-sri" iconWidth="10" titleWidth="0" />
                        </div>
                    )}
                    {type === 'light' && (
                        <div className="col-span-2 flex justify-end items-center relative pl-5 lg:pl-0">
                            <OptionCard imgSrc="/icons/document.svg" imgAlt="document" iconWidth="5" titleWidth="1/2" />
                            <img src="/images/light.png" alt="light" className="absolute w-40 -bottom-5 lg:bottom-7 left-2 lg:left-10" />
                        </div>
                    )}
                    {type === 'calendar' && (
                        <div className="col-span-2 flex justify-end items-center relative pl-5 lg:pl-0">
                            <OptionCard imgSrc="/icons/calendar.svg" imgAlt="calendar" iconWidth="5" titleWidth="1/3" />
                            <img src="/images/calendar.png" alt="calendar" className="absolute w-20 -bottom-5 lg:bottom-7 left-2 lg:-left-5" />
                        </div>
                    )}
                    {type === 'policy' && (
                        <div className="col-span-3 flex flex-col justify-center items-center px-5 lg:px-0">
                            <div className="w-full flex justify-center relative">
                                <div className="w-full max-w-[257px] border border-skeleton shadow-skeleton rounded-2xl overflow-hidden px-3 py-5">
                                    <div className="w-full flex justify-between items-center font-bold text-skeleton">
                                        Establece tu política
                                    </div>
                                    <div className="border border-skeleton w-full h-1 rounded-full mb-4 mt-2"></div>
                                    <div className="space-y-1">
                                        <SkeletonSection width="full" />
                                        <SkeletonSection width="full" />
                                        <SkeletonSection width="2/3" />
                                    </div>
                                    <div className="w-full flex justify-end">
                                        <img src="/icons/slide.svg" alt="slide" className="w-10 py-4" />
                                    </div>
                                    <div className="space-y-1">
                                        <SkeletonSection width="full" />
                                        <SkeletonSection width="full" />
                                        <SkeletonSection width="2/3" />
                                    </div>
                                    <div className="w-full flex justify-end">
                                        <img src="/icons/slide.svg" alt="slide" className="w-10 py-4" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </>
            )}
            {product === 'consultancy' && (
                <>
                    {type === 'create' && (
                        <div className="col-span-3 flex flex-col justify-center items-center relative px-5 lg:px-0">
                            <img src="/gif/carlton-dance.gif" alt="carlton-dance" className="w-28 absolute -top-3 left-1/2 -translate-x-1/2" />
                            <div className="w-full mt-20">
                                <AttachCard />
                            </div>
                        </div>
                    )}
                    {type === 'choose' && (
                        <div className="col-span-3 relative grid grid-cols-3 px-5 lg:px-0">
                            <div className="col-span-1">
                                <CardOffer />
                            </div>
                            <div className="col-span-2 w-full flex flex-col justify-between items-end">
                                <div className="w-full flex justify-end lg:justify-start">
                                    <CardChat type="video" align="right" />
                                </div>
                                <div className="w-full flex justify-end lg:justify-center">
                                    <CardChat type="text" align="left" />
                                </div>
                                <div className="w-full flex justify-end">
                                    <CardChat type="wait" align="left" />
                                </div>
                            </div>
                        </div>
                    )}
                    {type === 'answer' && (
                        <>
                            <div className="hidden lg:grid col-span-3 relative grid-cols-12 lg:grid-cols-3 pl-5 pr-0">
                                <div className="col-span-6 lg:col-span-1">
                                    <img src="/images/phoneApp.png" alt="phoneApp" className="w-full" />
                                </div>
                                <div className="col-span-6 lg:col-span-2 relative">
                                    <img src="/gif/respuestas.gif" alt="respuestas" className="w-full absolute top-1/2 -translate-y-1/2 right-0" />
                                </div>
                            </div>
                            <div className="col-span-3 w-full flex lg:hidden justify-center items-center relative">
                                <img src="/images/phoneApp.png" alt="phoneApp" className="w-1/2" />
                                <img src="/gif/respuestas.gif" alt="respuestas" className="w-full absolute top-1/2 -translate-y-1/2 right-0" />
                            </div>
                        </>
                    )}
                </>
            )}
            {product === 'documents' && (
                <>
                    {type === 'document' && (
                        <div className="col-span-3 flex justify-center items-center relative gap-5 px-5 lg:px-10">
                            <DocumentTypes />
                            <div className="w-full border-l border-dashed border-skeleton pl-5">
                                <div className="w-full shadow-skeleton flex justify-start items-start gap-2 rounded-lg border border-skeleton p-2">
                                    <img src="/icons/document-color.svg" alt="document" className="w-5" />
                                    <div className="w-full space-y-1">
                                        <SkeletonSection width="2/3" />
                                        <SkeletonSection width="1/2" />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <div className="border border-skeleton w-full h-2 rounded-full my-5 overflow-hidden p-[1px]">
                                        <div className="h-full w-1/3 bg-green-gradient90 rounded-full"></div>
                                    </div>
                                    <SkeletonSection width="2/3" />
                                    <SkeletonSection width="1/2" />
                                    <div className="opacity-70 space-y-1">
                                        <SkeletonSection width="full" />
                                        <SkeletonSection width="1/2" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {type === 'upload' && (
                        <div className="col-span-3 relative flex justify-start lg:justify-center items-end lg:items-center px-5 lg:px-0">
                            <img src="/images/phoneDocument.png" alt="phoneDocument" className="w-1/2 lg:w-1/3" />
                            <div className="absolute w-2/3 lg:w-1/2 right-0 bottom-10">
                                <img src="/gif/Moonwalk.gif" alt="Moonwalk" className="absolute w-3/5 -top-[85px] lg:-top-[108px] left-1/2 -translate-x-1/2" />
                                <AttachCard />
                            </div>
                        </div>
                    )}
                    {type === 'check' && (
                        <div className="col-span-3 relative flex justify-end lg:justify-center items-center pt-16 lg:pt-0 px-5 lg:px-0">
                            <div className="w-auto max-w-[220px] flex justify-between items-center p-3 gap-5 text-left border border-skeleton rounded-2xl absolute top-2 lg:top-7 left-0 lg:-left-5">
                                <div className='bg-green-gradient180 rounded-full p-[2px]'>
                                    <div className='flex flex-col justify-center items-center text-xs lg:text-base font-semibold lg:font-normal gap-3 lg:gap-0 border border-black w-10 h-10 rounded-full bg-white'>
                                        <img src={`/icons/ai.svg`} alt="ai" className='w-5 h-5 opacity-70' />
                                    </div>
                                </div>
                                <p className="font-bold text-xxs">Revisar el documento <br />con AI.</p>
                            </div>
                            <CardChat align="left" type="review" />
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default OptionType;