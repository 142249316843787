function HistoryCard({ item, position }: { item: any, position: string }) {
    return (
        <div className="relative tracking-tighter w-5/6 lg:w-full">
            <div className={`w-full col-span-2 lg:col-span-1 flex flex-col justify-between items-center border border-solid border-black rounded-2xl p-5 lg:p-5 bg-white h-64 md:h-48 lg:h-64 xl:h-52 gap-5 relative z-10 ${position === "left" ? "rounded-bl-none" : "rounded-br-none"}`}>
                <p className='font-lora italic leading-normal'>{item.description}</p>
                <div className="w-full">
                    <p className={`w-full ${position === "left" ? "text-left" : "text-right"}`}>{item.name}</p>
                    <p className={`w-full text-left font-bold ${position === "left" ? "text-left" : "text-right"}`}>{item.occupation}</p>
                </div>
            </div>
            <div className={`w-20 lg:w-24 h-20 lg:h-24 flex flex-col justify-center items-center p-1 border border-black rounded-full absolute -bottom-10 lg:-bottom-14 ${position === "left" ? "-left-16 lg:-left-20" : "-right-16 lg:-right-20"}`}>
                <img src={`/images/${item.img}`} alt={item.img} className='w-full h-full rounded-full border border-black object-cover' />
            </div>
        </div>
    );
}

export default HistoryCard;
