import { meta } from '../../utils/Info';
import DocumentMeta from 'react-document-meta';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import HomeSection from './components/HomeSection';
import MonitorSection from './components/MonitorSection';
import ConsultancySection from './components/ConsultancySection';
import DocumentSection from './components/DocumentSection';
import ProcedureSection from './components/ProcedureSection';
import UpdateSection from './components/UpdateSection';
import BenefitsSection from './components/BenefitsSection';
import HistorySection from './components/HistorySection';
import OurAlliesSection from './components/OurAlliesSection';
import PrivacySection from './components/PrivacySection';
import SolutionSection from './components/SolutionSection';
import QuestionSection from './components/QuestionSection';

function App() {
    return (
        <div className='w-full max-w-screen-2xl mx-auto flex flex-col justify-center items-center overflow-hidden'>
            <DocumentMeta {...meta} />

            <HomeSection />
            <div className='w-full px-5 lg:px-10'>
                <hr className='w-full border-black' />
            </div>

            <MonitorSection />

            <ConsultancySection />

            <DocumentSection />

            <ProcedureSection />

            <UpdateSection />
            <div className='w-full px-5 lg:px-10 pt-10'>
                <hr className='w-full border-black' />
            </div>

            <BenefitsSection />
            <div className='w-full px-5 lg:px-10 pt-10'>
                <hr className='w-full border-black' />
            </div>

            <HistorySection />
            <OurAlliesSection />
            <div className='w-full px-5 lg:px-10 pt-10'>
                <hr className='w-full border-black' />
            </div>
            <PrivacySection />
            <div className='w-full px-5 lg:px-10'>
                <hr className='w-full border-black' />
            </div>
            <SolutionSection />
            <div className='w-full px-5 lg:px-10'>
                <hr className='w-full border-black' />
            </div>
            <QuestionSection />

        </div>
    );
}

export default App;
