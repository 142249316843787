import { solutionSection } from '../../../utils/Info';
import ButtonGreen from '../../../components/ButtonGreen';


export default function SolutionSection() {
    return (
        <section className='w-full px-5 lg:px-10'>
            <div className='w-full flex flex-col justify-start items-center pt-10 pb-10 gap-10'>
                <div className='flex flex-col justify-center items-center gap-10'>
                    <h2 className='font-bold text-2xl lg:text-3xl tracking-tighter whitespace-pre-line leading-tight lg:leading-snug text-center'>{solutionSection.title}</h2>
                </div>
                <div className='grid grid-cols-1 lg:grid-cols-3 gap-5'>
                    {
                        solutionSection.solutions.map(solution =>
                            <div key={solution.title} className='border-05 border-black bg-white bg-opacity-60 rounded-xl px-5 py-10 flex flex-col justify-center items-center gap-10'>
                                <p className='font-bold text-lg lg:text-xl'>{solution.title}</p>
                                <p className='w-full md:w-2/3 lg:w-full h-24 md:h-20 lg:h-24 text-center text-sm xl:text-base'>{solution.description}</p>
                                <a className='w-48 block' href={solution.buttonLink}>
                                    <ButtonGreen title={solution.button} />
                                </a>
                            </div>
                        )
                    }
                </div>
            </div>
        </section>
    )
}