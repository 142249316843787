import { monitorSection } from '../../../utils/Info';
import ButtonBlackBR from '../../../components/ButtonBlackBR';
import Button from '../../../components/Button';
import SectionHeader from '../../../components/SectionHeader';


export default function MonitorSection() {
    return (
        <section className='w-full px-5 lg:px-10'>
            <SectionHeader sectionName={monitorSection.sectionName} sectionDescription={monitorSection.sectionDescription} />
            <div className='w-full bg-white bg-opacity-60 flex flex-col justify-end items-center gap-10 border-05 border-black rounded-xl pt-16'>
                <h2 className='font-bold text-2xl lg:text-3xl tracking-tighter whitespace-pre-line leading-tight lg:leading-snug text-center'>{monitorSection.title}</h2>
                <div className='w-5/6 lg:w-3/5 text-center font-light tracking-tighter text-sm lg:text-base'>
                    <p>{monitorSection.description}</p>
                </div>
                <div className='w-3/5 flex flex-col md:flex-row justify-center items-center gap-5 lg:gap-20'>
                    <a className='w-48 block' href={monitorSection.buttonLeftLink}>
                        <Button title={monitorSection.buttonLeft} />
                    </a>
                    <a className='w-48 block' href={monitorSection.buttonRightLink}>
                        <ButtonBlackBR title={monitorSection.buttonRight} />
                    </a>
                </div>
                <div className='w-full relative'>
                    {/* <img src={`/images/${monitorSection.imageBottom}`} alt={monitorSection.imageBottom} className='w-[140%] md:w-[69%] max-w-none absolute bottom-2 lg:-bottom-[69%] translate-y-1/2 right-auto left-[65%] -translate-x-1/2 md:-right-5 lg:-right-8 md:left-auto md:translate-x-0 z-20 animate-octopus' /> */}
                    <img src={`/images/${monitorSection.imageBottom}`} alt={monitorSection.imageBottom} className='w-[140%] md:w-[69%] max-w-none absolute -bottom-[70%] lg:-bottom-[69%] -right-1/3 md:-right-5 lg:-right-8 -translate-y-1/2 -translate-x-1/2  z-20 animate-octopus' />
                    <img src={`/images/${monitorSection.imageCenter}`} alt={monitorSection.imageCenter} className='w-4/5 md:w-2/5 mx-auto' />
                </div>
            </div>
        </section>
    )
}