import SkeletonSection from "./SkeletonSection";

export default function CardOffer() {
    return (
        <div className="shadow-skeleton w-full lg:w-2/3 mx-auto border border-skeleton bg-white rounded-xl h-full p-2">
            <div className="bg-skeleton rounded-lg bg-opacity-80 overflow-hidden relative mb-3">
                <img src="/icons/user.svg" alt="user" className="w-full mt-9 -mb-1" />
                <img src="/icons/play.svg" alt="play" className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-6" />
            </div>
            <div className="space-y-2 flex flex-col items-center">
                <SkeletonSection width="full" />
                <SkeletonSection width="5/6" />
                <SkeletonSection width="1/2" />
                <SkeletonSection width="10" />
                <SkeletonSection width="3/5" />
                <img src="/icons/check.svg" alt="check" />
                <SkeletonSection width="3/5" />
                <img src="/icons/none.svg" alt="none" />
                <SkeletonSection width="3/5" />
                <SkeletonSection width="1/2" />
                <SkeletonSection width="5/6" />
                <div className="relative h-6 w-full !mt-5 cursor-pointer">
                    <span className="bottomRight-top absolute bg-green-gradient90 h-full w-full -bottom-1 -right-1 rounded-lg"></span>
                    <button className='bottomRight-bottom relative z-10 h-full w-full font-semibold border border-solid border-black rounded-lg text-xxs'></button>
                </div>
            </div>
        </div>
    )
}