import { classNames } from "primereact/utils";

export const themePrime = {
    root: { className: 'font-spartan' },
    accordion: {
        root: { className: 'w-full' },
    },
    accordionTab: {
        root: { className: 'bg-transparent mb-0' },
        header: ({ props, context }: { props: any, context: any }) => ({
            className: classNames(
                '',
                // { 'opacity-1': context.disabled },
                { '!opacity-100 pointer-events-auto bg-green-table-mobile pb-07': props.className === 'menu-mobile' },
            )
        }),
        headerAction: ({ props, context }: { props: any, context: any }) => ({
            className: classNames(
                'bg-transparent font-medium text-lg font-lora border-b-05 border-black rounded-none px-0 py-10 lg:py-7',
                { '!bg-black text-base border-none !py-7 !px-5': props.className === 'menu-mobile' }
            )
        }),
        headerIcon: ({ props, context }: { props: any, context: any }) => ({
            className: classNames(
                'hidden',
                { 'hidden': props.className === 'menu-mobile' }
            )
        }),
        content: ({ props, context }: { props: any, context: any }) => ({
            className: classNames(
                'bg-transparent font-spartan text-sm lg:text-base border-b-05 border-black rounded-none py-10 px-0',
                { '!bg-black !text-xs border-none !py-7 !px-5 text-white': props.className === 'menu-mobile' }
            )
        }),
    },
    // menubar: {
    //     root: { className: 'font-spartan text-sm bg-transparent m-0 p-0' },
    //     content: { className: 'bg-transparent m-0 p-0' },
    //     action: { className: 'bg-transparent m-0 px-3 text-black' },
    //     label: { className: 'text-black' },
    //     submenuicon: { className: 'text-black' },
    //     submenu: {
    //         className: 'bg-black text-white',
    //         label: { className: 'text-white' },

    //     },
    // }
};