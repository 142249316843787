import SkeletonSection from "./SkeletonSection";

export default function AttachCard() {
    const FileSection = () => (
        <div className="w-full flex justify-between items-center px-1">
            <img src="/icons/file.svg" alt='file' className="w-3" />
            <SkeletonSection width="2/3" />
            <img src="/icons/closeCircle.svg" alt='closeCircle' className="w-3" />
        </div>
    );

    return (
        <div className="w-full mx-auto max-w-[230px] h-[175px] border border-skeleton rounded-2xl overflow-hidden p-3 relative z-10 bg-white">
            <div className="space-y-1">
                <FileSection/>
                <FileSection/>
                <FileSection/>
            </div>
            <div className="w-full pt-5 flex flex-col justify-center items-center gap-5">
                <img src="/icons/attach.svg" alt='attach' className="w-10" />
                <SkeletonSection width="4/5" />
            </div>
        </div>
    )
};