import ButtonWhite from '../../../components/ButtonWhite';
import { questionSection } from '../../../utils/Info';
import { Accordion, AccordionTab } from 'primereact/accordion';


export default function QuestionSection() {
    return (
        <section className='w-full px-5 lg:px-10'>
            <div className='w-full flex flex-col justify-start items-center pt-10 pb-10 gap-10'>
                <div className='flex flex-col justify-center items-center gap-10'>
                    <h2 className='font-bold text-2xl lg:text-3xl tracking-tighter whitespace-pre-line leading-tight lg:leading-snug text-center'>{questionSection.title}</h2>
                </div>
                <div className='w-full lg:w-5/6 xl:w-2/3 border-05 border-black bg-white bg-opacity-60 rounded-xl pt-5 px-7 pb-10 lg:px-10 lg:py-10 tracking-tighter'>
                    <Accordion activeIndex={0}>
                        {
                            questionSection.questions.map(solution =>
                                <AccordionTab key={solution.question} header={solution.question}>
                                    <p className="m-0">
                                        {solution.answer}
                                    </p>
                                </AccordionTab>
                            )
                        }
                    </Accordion>
                    <div className='bg-green my-10 px-10 py-16 lg:py-10 flex flex-col lg:flex-row gap-10 justify-between items-center rounded-lg'>
                        <div>
                            <p className='font-bold text-2xl mb-2 text-center lg:text-left'>{questionSection.alertTitle}</p>
                            <p className='text-center lg:text-left text-sm lg:text-base'>{questionSection.alertText}</p>
                        </div>
                        <a className='w-48 block' href={questionSection.alertButtonLink}>
                            <ButtonWhite title={questionSection.alertButton} />
                        </a>
                    </div>
                </div>
            </div>
        </section>
    )
}