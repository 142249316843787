import DocumentMeta from "react-document-meta";
import { meta } from "../utils/Info";

export default function NotFound() {
    return (
        <div className="w-full flex flex-col justify-center items-center pt-10 gap-10">
            <DocumentMeta {...meta} />

            <p className='font-extrabold text-2xl lg:text-5xl whitespace-pre-line leading-tight text-center tracking-tighter'>404</p>
            <p className='font-light text-2xl lg:text-5xl whitespace-pre-line leading-tight text-center tracking-tighter'>Página no encontrada</p>
            <img src="/gif/notFound.gif" alt="notFound" />
        </div>
    )
}