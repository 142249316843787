import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export default function HeaderLink({ menu }: { menu: any }) {
    const location = useLocation();
    const { pathname } = location;
    const [isHovered, setIsHovered] = useState(false);
    const navigate = useNavigate();

    const handleMouseEnter = () => {
        // menu.hasOwnProperty('submenu') && setIsHovered(true);
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        // menu.hasOwnProperty('submenu') && setIsHovered(false);
        setIsHovered(false);
    };

    const navigateOption = () => {
        menu.submenu ?? navigate(menu.url)
    };

    return (
        <div className="relative h-full cursor-pointer" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}
            onClick={navigateOption}
        >
            <div className={`h-full flex justify-center items-center ${isHovered && "font-semibold"}`}>
                {menu.label}
                {pathname.includes(menu.url) && <div className='w-2 h-2 bg-green-gradient180 rounded-full absolute top-10 left-1/2 -translate-x-1/2'></div>}
            </div>
            {
                menu.hasOwnProperty('submenu') && isHovered && (
                    <div className="absolute w-60 top-11 left-2 bg-black py-10 rounded-xl rounded-tl-none text-white z-20">
                        {
                            menu.submenu.map((submenu: any) => (
                                <div>
                                    <p className='w-full font-bold text-xs lg:text-sm mb-5 bg-green-text bg-clip-text text-transparent px-5'>{submenu.section}</p>
                                    <div className="bg-green-table-mobile">
                                        {
                                            submenu.items.map((item: any) => (
                                                pathname.includes(item.url) ?
                                                    <a href={item.url} className="block bg-black mb-05 py-3 px-8 font-semibold cursor-pointer">{item.label}</a>
                                                    :
                                                    <a href={item.url} className="block bg-black mb-05 py-3 px-8 hover:font-semibold cursor-pointer">{item.label}</a>
                                            ))
                                        }
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                )
            }
        </div>
    )
}