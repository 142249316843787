import ConsultTypes from "../pages/Producto/components/ConsultTypes"

export const monitor = {
    section: "Funcionalidad",
    title: "monitor",
    subtitle: "¿Estás listo para llevar tu negocio al siguiente nivel sin preocuparte por riesgos ocultos o incumplimientos legales? ",
    description: "En Consultopia, entendemos tus desafíos y hemos creado un módulo inteligente basado en robotización e inteligencia artificial para ser tu aliado en el camino hacia el éxito.",
    options: [
        {
            chip: "Siempre alerta",
            title: "Vigilancia inteligente de entidades de control",
            align: "left",
            type: "sri",
            confeti: false,
            description: <>
                <p>El Monitor inspecciona constantemente la información de entidades como el SRI, superintendencias, seguridad social, función judicial, entre otras.</p>

                <p className="text-sm border-l-6 border-black pl-2">
                    <b>Con tu número de RUC, accedemos periódicamente a información pública y te alertamos sobre irregularidades.</b>
                </p>

                <p>Si lo prefieres, y con tu autorización, podemos realizar análisis más detallados solicitándote acceso a tus cuentas en ciertas entidades de control.</p>

                <p>Cuando detectamos novedades, descargamos la información, la resumimos y nuestro asesor IA te explica los riesgos en detalle.</p>
            </>,
            video: "malo"
        },
        {
            chip: "Revisión 360",
            title: "Análisis de documentos y evaluación de riesgos",
            align: "right",
            type: "light",
            confeti: false,
            description: <>
                <p>Carga tus documentos en la plataforma y el monitor los clasificará y evaluará para identificar posibles riesgos.</p>
                <p><b>Obtén una visión completa y actualizada</b> que te permita tomar decisiones informadas y proteger tu negocio de manera proactiva.</p>
            </>,
            video: "bueno"
        },
        {
            chip: "Avisos tempranos",
            title: "Recordatorio de obligaciones",
            align: "left",
            type: "calendar",
            confeti: false,
            description: <>
                <p>Nuestro Monitor cuenta con una base de conocimiento sobre las obligaciones que debes cumplir, ya seas un profesional con RUC o una compañía.</p>
                <p>Te alertamos cuando se acercan fechas importantes, para que nunca más olvides un plazo crucial.</p>
            </>,
            video: "theOffice"
        },
        {
            chip: "Cumplimiento personalizado",
            title: "Políticas personalizadas y detección de incumplimientos",
            align: "right",
            type: "policy",
            confeti: false,
            description: <>
                <p>Puedes redactar políticas personalizadas dentro de la plataforma. Utilizando IA, contrastamos los riesgos detectados con tus políticas y te alertamos sobre cualquier incumplimiento.</p>
                <p><b>Recibe notificaciones push y correos electrónicos</b> cuando detectamos alguna amenaza potencial. Mantente siempre un paso adelante y enfócate en hacer crecer tu negocio.</p>
            </>,
            video: ""
        }
    ],
}

export const consultancy = {
    section: "Funcionalidad",
    title: "consultancy",
    subtitle: "¿Necesitas asesoría legal rápida y confiable?",
    description: "En Consultopia, hemos simplificado el proceso de consultas legales para que obtengas respuestas precisas cuando más las necesitas.",
    options: [
        {
            chip: "Simplicidad radical",
            title: "Realiza tu consulta de manera fácil y flexible",
            align: "left",
            type: "create",
            confeti: true,
            description: <>
                <p>Envía tu consulta por audio, texto o video, y adjunta cualquier documento relevante que desees que se considere.</p>
                <p className="text-sm border-l-6 border-black pl-2">
                    <b> Nuestra plataforma te permite expresarte de la forma que más te convenga. </b>
                </p>
                <ConsultTypes />
            </>,
        },
        {
            chip: "Vínculo soñado",
            title: "Elige al consultor ideal para ti",
            align: "right",
            type: "choose",
            confeti: false,
            description: <>
                <p>Una vez que envíes tu consulta, recibirás cotizaciones de diferentes abogados y de <b>nuestro consultor AI</b> durante un período de tiempo.</p>
                <p>Podrás comparar sus propuestas y seleccionar al consultor que mejor se adapte a <b>tus necesidades y presupuesto</b>.</p>
                <p>Al elegir a un consultor, podrán interactuar para responder preguntas o proporcionar información adicional que mejore la calidad de la respuesta.</p>
            </>,
        },
        {
            chip: "Obtén claridad",
            title: "Recibe tu respuesta en el tiempo indicado",
            align: "left",
            type: "answer",
            confeti: false,
            description: <>
                <p>Los consultores responderán tus consultas por video. Te indicaremos el tiempo máximo de respuesta, manteniéndote siempre informado.</p>
                <p><b>Nuestro consultor AI está disponible 24/7 y te entregará respuestas en menos de 10 minutos</b>. Tu respuesta incluirá un video, un informe y consejos gerenciales enfocados en tu problema.</p>
                <p>Recibe notificaciones push y correos electrónicos cuando haya novedades sobre tus consultas.</p>
            </>,
        }
    ],
}

export const documents = {
    section: "Funcionalidad",
    title: "documents",
    subtitle: "¿Quieres gestionar tus documentos legales de forma fácil, eficiente y sin complicaciones?",
    description: "En Consultopia, sabemos lo abrumador que puede ser manejar documentación legal. Por eso, hemos creado una herramienta para simplificar y agilizar la documentación de tu negocio.",
    options: [
        {
            chip: "Simplicidad radical",
            title: "Genera Documentos Legales Personalizados al Instante",
            align: "left",
            type: "document",
            confeti: true,
            description: <>
                <p>Accede a nuestro  catálogo de documentos legales, que incluye contratos de trabajo, arrendamientos, documentos societarios y mucho más.</p>
                <p className="text-sm border-l-6 border-black pl-2">
                    <b> Olvídate de las plantillas en Word. Con Consultopia, solo completa unas preguntas en nuestra app y obtén en segundos un documento personalizado, adaptado a tus necesidades y a la normativa vigente. </b>
                </p>
                <p> Esto no solo te ahorra tiempo, sino que también te brinda la tranquilidad de saber que tus documentos están correctamente elaborados.</p>
            </>,
        },
        {
            chip: "Vínculo soñado",
            title: "Carga y Resume tus Documentos Existentes",
            align: "right",
            type: "upload",
            confeti: false,
            description: <>
                <p><b>¿Tienes documentos legales propios y necesitas gestionarlos mejor?</b></p>
                <p>Sube tus archivos a nuestra plataforma de forma segura y confidencial.</p>
                <p>Nuestro sistema clasificará cada documento automáticamente según su tipo y contenido, y te ofrecerá un <b>resumen detallado con los puntos clave</b> y las cláusulas más importantes.</p>
                <p>Así podrás entender rápidamente el contenido esencial sin leer páginas de texto legal.</p>
            </>,
        },
        {
            chip: "Obtén claridad",
            title: "Revisa e Interactúa con tus Documentos mediante IA",
            align: "left",
            type: "check",
            confeti: false,
            description: <>
                <p><b>Solicita Revisión de Documentos:</b> Nuestra inteligencia artificial analiza tus documentos y, en minutos, te ofrece un informe diagnóstico que identifica inconsistencias con la normativa, riesgos legales y oportunidades de mejora. Así, podrás corregir errores y fortalecer tus documentos para asegurar tu protección legal.</p>
                <br />
                <p><b>Consulta a tu Analista IA:</b> ¿Tienes dudas sobre tus documentos? Interactúa con nuestro Analista IA mediante un chat intuitivo y fácil de usar. Haz preguntas específicas o generales y recibe respuestas inmediatas. Esta función te da acceso instantáneo a información, sin esperas ni citas.</p>
            </>,
        }
    ],
}