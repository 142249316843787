import OptionGif from "./OptionGif";
import OptionType from "./OptionType";

interface CardOptionProps {
    option: any;
    product: any;
}

export default function CardOption({ option, product }: CardOptionProps) {
    return (
        <div className={`w-full lg:w-5/6 mx-auto space-y-5 text-left ${option.align === 'right' && "lg:text-right"}`}>
            <p className="w-full lg:mx-0 font-lora font-bold text-lg leading-tight pb-5 px-3">{option.title}</p>
            <div className="inline font-lora text-base bg-green-gradient90-op bg-opacity-60 px-3 py-2 rounded-lg mx-3">{option.chip}</div>
            <div className="w-full min-h-[381px] bg-white bg-opacity-60 border-05 border-black rounded-lg text-sm grid grid-cols-12 relative py-5 lg:py-0">
                <div className={`space-y-7 lg:space-y-10 text-left flex flex-col justify-center items-center col-span-12 p-5 text-sm lg:text-base order-1 ${option.align === 'right' ? "lg:order-2 lg:col-span-6 lg:p-10 lg:pl-20" : "lg:order-1 lg:col-span-7 lg:p-10 lg:pr-20"}`}>
                    {option.description}
                </div>
                <div className={`grid grid-cols-3 gap-3 relative px-0 py-5 pt-16 lg:p-10 lg:pr-0 col-span-12 order-2  ${option.align === 'right' ? "lg:order-1 lg:col-span-6" : "lg:order-2 lg:col-span-5"}`}>
                    <OptionType type={option.type} product={product} />
                    {
                        option.video &&
                        <OptionGif video={option.video} />
                    }
                    {/* {
                        option.confeti &&
                        <img src="/gif/confeti_lado_lado.gif" alt="confeti_lado_lado" className="w-full h-full absolute top-0 right-0 object-cover" />
                    } */}
                </div>
            </div>
        </div>
    )
}