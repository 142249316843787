import SkeletonSection from "./SkeletonSection";

interface CardChatProps {
    align: 'left' | 'right';
    type: 'video' | 'text' | 'wait' | 'review';
}

export default function CardChat({ align, type }: CardChatProps) {
    return (
        <div className={`w-11/12 lg:w-auto inline-block shadow-skeleton rounded-xl border border-skeleton relative bg-white ${align === "left" ? "rounded-bl-none" : "rounded-br-none"}`}>
            {type === 'video' && (
                <div className="w-full lg:max-w-[220px] flex justify-between items-center p-4 gap-3 lg:gap-7 text-left">
                    <p className="font-bold text-xxs">Consulta <br /> realizada</p>
                    <div className="flex justify-center items-center gap-1 lg:gap-3">
                        <img src="/icons/video-transparent.svg" alt="video-transparent" className="w-10" />
                        <img src="/icons/play.svg" alt="play" className="w-10" />
                    </div>
                </div>
            )}
            {type === 'text' && (
                <div className="w-full lg:max-w-[220px] p-4 text-left text-xxs inline-block">
                    <div className="w-2/3 inline-block">
                        <SkeletonSection width="full" />
                    </div> ha sido <br /> asignado a tu consulta y está <br /> preparando tu respuesta
                </div>
            )}
            {type === 'wait' && (
                <div className="w-full lg:max-w-[220px]  text-left text-xs inline-block">
                    <p className="font-semibold text-xxs text-center py-3 border-b border-skeleton px-5">Tu asesor legal esta <br /> trabajando en tu respuesta</p>
                    <div className="w-full relative h-[110px]">
                        <img src="/images/photoframe.png" alt="photoframe" className="w-full h-full object-cover rounded-br-lg" />
                        <img src="/gif/trabajando.gif" alt="trabajando" className="absolute z-10 bottom-0 left-0 rounded-br-lg" />
                    </div>
                </div>
            )}
            {type === 'review' && (
                <div className="w-full lg:max-w-[220px] text-left text-xs inline-block">
                    <p className="font-semibold text-xxs text-center py-3 border-b border-skeleton mx-5">
                        <SkeletonSection width="40" />
                    </p>
                    <div className="w-full relative py-5">
                        <img src="/icons/review.svg" alt="review" className="w-10 mx-auto" />
                        <div className="bg-skeleton-light w-1/2 h-5 rounded mx-auto mt-3"></div>
                    </div>
                </div>
            )}
            <img src={`/icons/${align === "left" ? "user-chat-consultopia" : "user-chat"}.svg`} alt="chat-user" className={`absolute -bottom-2 hidden lg:block ${align === "left" ? "-left-10" : "-right-12"}`} />
        </div>
    )
}