import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { header } from '../utils/Info';
import HeaderLink from './HeaderLink';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { useNavigate } from 'react-router-dom';

function Header() {
    // const withoutHeader = ["/mobile/customer"];
    const location = useLocation();
    const { pathname } = location;
    const [showMenu, setShowMenu] = useState(false);
    console.log('pathname', pathname)
    const navigate = useNavigate();
    const [activeIndex, setActiveIndex] = useState(null);

    useEffect(() => {
        if (showMenu) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';
        }
        return () => {
            document.body.style.overflow = '';
        };
    }, [showMenu]);

    const handleTabChange = (e: any, menu: any) => {
        if (!menu.submenu) {
            setActiveIndex(null);
            setShowMenu(false);
            navigate(menu.url);
        } else {
            setActiveIndex(e.index);
        }
    };

    return (
        !pathname.includes("/mobile/customer") ?
            <div className='w-full max-w-screen-2xl mx-auto flex justify-center items-center z-30 px-5 xl:px-10 h-28 relative' >
                <div className='w-full max-w-screen-2xl h-12 xl:h-[59px] px-5 xl:px-10 flex justify-between xl:justify-around items-center border-05 border-black bg-white bg-opacity-60 rounded-lg xl:rounded-xl relative'>
                    <div className='w-1/2'>
                        <a href="/">
                            <img src={`/icons/${header.logo}.svg`} alt="logo" />
                        </a>
                    </div>
                    <div className='hidden lg:flex absolute left-1/2 -translate-x-1/2 justify-center items-end'>
                        <img src={`/icons/${header.iconCenter}.svg`} alt="iconCenter" />
                    </div>
                    <div className='hidden xl:flex w-1/2 h-full justify-end items-center gap-8 text-xs'>
                        {
                            header.options.map((item: any) => item.show !== 'mobile' && <HeaderLink key={item.label} menu={item} />)
                        }
                    </div>
                    <div className='w-1/2 flex xl:hidden justify-end'>
                        <img src={`/images/menu.svg`} alt="logo" onClick={() => setShowMenu(true)} />
                    </div>
                </div>
                {
                    showMenu ?
                        <div className='block xl:hidden w-full h-[100dvh] fixed z-30 top-0 left-0 bg-black px-5 pb-10 overflow-hidden'>
                            <div className='h-28 w-full flex justify-center items-center'>
                                <div className='w-full bg-gradient-to-r from-white via-black to-black py-05 pl-05 h-11 rounded-lg overflow-hidden'>
                                    <div className='bg-black px-5 h-full flex justify-between items-center rounded-lg rounded-r-none'>
                                        <div className='w-1/2'>
                                            <img src={`/icons/${header.logo}.svg`} alt="logo" />
                                        </div>
                                        <img src={`/icons/close.svg`} alt="close" className='w-5' onClick={() => setShowMenu(false)} />
                                    </div>
                                </div>
                            </div>
                            <div className="text-white font-spartan">
                                <Accordion className='menu-mobile' activeIndex={activeIndex} onTabChange={(e: any) => handleTabChange(e, header.options[e.index])}>
                                    {
                                        header.options.map((menu: any) => menu.show !== 'web' && (
                                            <AccordionTab
                                                header={
                                                    <div className='relative'>
                                                        {pathname === menu.url && <div className='w-2 h-2 bg-green-gradient180 rounded-full absolute -left-5 top-1/2 -translate-y-1/2'></div>}
                                                        {menu.label}
                                                    </div>
                                                }
                                            >
                                                {
                                                    menu.submenu &&
                                                    menu.submenu.map((submenu: any) => (
                                                        <div>
                                                            {
                                                                submenu.items.map((item: any) => (
                                                                    <p className="py-4 px-8"><a href={item.url}>{item.label}</a></p>
                                                                ))
                                                            }
                                                        </div>
                                                    ))
                                                }
                                            </AccordionTab>
                                        ))
                                    }
                                </Accordion>
                            </div>
                        </div>
                        : null
                }
            </div>
            : null
    );
}

export default Header;
