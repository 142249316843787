import SkeletonSection from "./SkeletonSection"

export default function DocumentTypes() {
    const options = ["company", "briefcase", "money", "contract"]
    return (
        <div className='w-full relative flex flex-col justify-center items-center gap-3'>
            {
                options.map(item =>
                    <div className='w-full border border-skeleton rounded-lg p-2 flex justify-between items-center'>
                        <div className="w-4/5 flex justify-start items-center gap-2">
                            <img src={`/icons/${item}.svg`} alt={item} className='w-5 h-5 opacity-70' />
                            <SkeletonSection width="1/2" />
                        </div>
                        <img src={`/icons/arrow-down.svg`} alt="arrow-down" className='w-3 opacity-70' />
                    </div>
                )
            }
        </div >
    )
}