import ButtonWhite from '../../../components/ButtonWhite';
import { privacySection } from '../../../utils/Info';

export default function PrivacySection() {
    return (
        <section className='w-full px-5 lg:px-10 pb-10'>
            <div className='py-10 flex justify-between items-center'>
                <h2 className='font-bold text-2xl lg:text-3xl tracking-tighter whitespace-pre-line leading-tight lg:leading-snug text-center w-full'>{privacySection.title}</h2>
            </div>
            <div className='w-full bg-black rounded-xl px-5 py-10 lg:p-20 flex flex-col justify-center items-center gap-10'>
                <p className='w-full lg:w-2/3 text-white text-center tracking-tighter text-sm lg:text-base pb-10 lg:pb-0'>
                    {privacySection.description}
                </p>
                <a className='w-48 block' href={privacySection.buttonLink}>
                    <ButtonWhite title={privacySection.button} />
                </a>
            </div>
        </section>
    )
}