export const sectionList = [
    "BIENVENIDO",
    "NUESTRO SERVICIO",
    "PROTEGE TU NEGOCIO",
    "CÓMO FUNCIONA",
    "A TU MEDIDA",
    "FACILIDAD",
    "MEMBRESÍAS",
    "CLIENTES FELICES"
]

export const header = {
    logo: "logo",
    iconCenter: "bowtie",
    options: [
        {
            label: "Inicio",
            url: "/",
            show: "mobile",
        },
        {
            label: "Producto",
            url: "/product",
            show: "both",
            submenu: [
                {
                    section: "Funcionalidades",
                    items: [
                        {
                            label: 'Monitor AI',
                            url: '/product/monitorai'
                        },
                        {
                            label: 'Consultoría 3.0',
                            url: '/product/consultancy'
                        },
                        {
                            label: 'Documentos Smart',
                            url: '/product/document'
                        },
                        {
                            label: 'Tramites ágiles',
                            url: '/product/procedure'
                        },
                        {
                            label: 'Actualización oportuna',
                            url: '/product/update'
                        },
                    ]
                }
            ]
        },
        {
            label: "Soluciones",
            url: "/solutions",
            show: "both",
        },
        {
            label: "Precios",
            url: "/price",
            show: "both",
        },
        {
            label: "Propósito",
            url: "/purpose",
            show: "both",
        },
        {
            label: <b>Empezar gratis</b>,
            url: "https://www.consultopia.app/mobile/customer/login",
            show: "web",
        }
    ],
    // begin: {
    //     label: "Empezar gratis",
    //     link: "https://www.consultopia.app/mobile/customer/login"
    // },
}

export const homeSection = {
    title: <>
        La <span className="text-border font-bold bg-green-text bg-clip-text text-transparent">app</span> que protege tu negocio con <br className="hidden lg:block" /> asesoría legal <span className="text-border font-bold bg-green-text bg-clip-text text-transparent">inteligente</span>
    </>,
    subtitle: "Supera obstáculos legales y obtén seguridad estratégica.",
    description: "Enfócate en tu propósito y construye un legado de impacto.",
    buttonLeft: "Empezar gratis",
    buttonLeftLink: "https://www.consultopia.app/mobile/customer/login",
    buttonRight: <div className="w-full flex justify-center items-center gap-2">
        <img src="/icons/play-white.svg" alt="play-white" />
        <span className="pt-[2px]">Ver una demo</span>
    </div>,
    buttonRightLink: "#",
    gif: "WelcomeCarousel.gif",
    textLeft: "lo hacemos con",
    textLeftBold: "IA",
    textRight: "para negocios",
    textRightBold: "WOW"
}

export const monitorSection = {
    sectionName: <>Monitor <b>IA</b> </>,
    sectionDescription: "Cuidando de ti y tu negocio",
    title: "Anticípate a riesgos y obligaciones \n con tranquilidad",
    description: <>
        Nuestro Monitor IA analiza constantemente tu negocio, identificando posibles amenazas legales antes de que se conviertan en problemas. <br /> <br />
        Con <span className="font-semibold">alertas</span> y <span className="font-semibold">recomendaciones en tiempo real</span>, te ayudamos a mantenerte informado y cumplir con tus obligaciones, para que puedas concentrarte en lo que realmente importa.
    </>,
    buttonLeft: "Conocer más",
    buttonLeftLink: "#",
    buttonRight: "Empezar gratis",
    buttonRightLink: "https://www.consultopia.app/mobile/customer/login",
    imageCenter: "radar.png",
    imageBottom: "octopus.png",
}

export const consultancySection = {
    sectionName: <>Consultoría <b>3.0</b> </>,
    sectionDescription: "Escuchándote y brindando apoyo",
    title: "Accede a asesoría legal personalizada \n cuando la necesites",
    description: <>
        Conecta por video, audio o texto con abogados especializados y consultores de inteligencia artificial que entienden tus inquietudes y te ofrecen respuestas rápidas y soluciones adaptadas a tus necesidades.<br /> <br />
        Toma decisiones informadas <span className="font-semibold">sin perder tiempo</span>.
    </>,
    buttonLeft: "Conocer más",
    buttonLeftLink: "#",
    buttonRight: "Empezar gratis",
    buttonRightLink: "https://www.consultopia.app/mobile/customer/login",
    options: ["video", "voice", "text", "call"],
    phoneCard: [
        <>Realiza consultas <b>24/7</b> </>,
        <>Recibe tu respuesta por <b>video</b> </>,
        <>Interactúa con <b>análisis estratégicos</b> </>
    ]
}

export const documentSection = {
    sectionName: <>Documentos <b>SMART</b> </>,
    sectionDescription: "Simplificando lo complejo",
    title: "Simplifica la gestión de tus \n documentos legales",
    description: <>
        Genera contratos y acuerdos en minutos, carga tus propios documentos y <span className="font-semibold">recibe revisiones detalladas</span> para asegurar que todo esté en orden.  <br /> <br />

        Además, interactúa directamente con tus documentos a través de un analista IA, permitiéndote <span className="font-semibold">chatear y consultar aspectos relevantes</span> de cada uno.
    </>,
    buttonLeft: "Conocer más",
    buttonLeftLink: "#",
    buttonRight: "Empezar gratis",
    buttonRightLink: "https://www.consultopia.app/mobile/customer/login",
    imageCenter: "eclipse.png",
    imageBottom: "meditate.png",
}

export const procedureSection = {
    sectionName: <>Trámites <b>ÁGILES</b> </>,
    sectionDescription: "Acompañándote en Cada Paso",
    title: "Facilita tus trámites empresariales \n con asistencia personalizada",
    description: <>
        Accede a un catálogo de procesos clave y recibe <span className="font-semibold">apoyo paso a paso</span> de abogados y consultores IA para superar obstáculos, ahorrar tiempo y evitar errores.<br /> <br />
        Avanza con confianza y enfócate en alcanzar tus metas.
    </>,
    buttonLeft: "Conocer más",
    buttonLeftLink: "#",
    buttonRight: "Empezar gratis",
    buttonRightLink: "https://www.consultopia.app/mobile/customer/login",
    phoneCard: [
        <>Identifica <b>procesos prioritarios</b> </>,
        <>Mantente informado con <b>tracking</b> </>
    ]
}

export const updateSection = {
    sectionName: <>Actualización <b>OPORTUNA</b> </>,
    sectionDescription: "Manteniéndote Conectado",
    title: "La información es poder",
    description: <>
        Mantente al día con información relevante y toma decisiones estratégicas con datos actualizados.<br /> <br />

        Recibe noticias, indicadores económicos, cotizaciones de criptomonedas y consejos personalizados para fortalecer tu crecimiento personal y profesional, manteniendo una conexión sólida con el entorno y contigo mismo.
    </>,
    buttonLeft: "Conocer más",
    buttonLeftLink: "#",
    buttonRight: "Empezar gratis",
    buttonRightLink: "https://www.consultopia.app/mobile/customer/login",
    news: ["mundial", "nacional", "normativa", "indicadores", "criptomonedas", "consejos"]
}

export const benefitsSection = {
    title: "Beneficios estratégicos para \n impulsar tu negocio",
    benefits: [
        {
            title: "Reducción de riesgos legales",
            description: <>Minimiza la posibilidad de enfrentar multas y sanciones. Evita costos imprevistos y <b>protege la reputación de tu negocio</b>.</>
        },
        {
            title: "Ventaja competitiva",
            description: <>Aprovecha insights legales que pueden marcar la diferencia en tu industria. Identifica oportunidades y <b>evita obstáculos que otros pasan por alto</b>.</>
        },
        {
            title: "Optimización de recursos",
            description: <>Reduce los gastos asociados con servicios legales tradicionales. <b>Cuida tu presupuesto</b> sin sacrificar la calidad o la efectividad.</>
        },
        {
            title: "Eficiencia operativa",
            description: <>Agiliza tareas administrativas y legales, <b>aumentando la productividad de tu equipo</b>. </>
        },
        {
            title: "Crecimiento sostenible",
            description: <>Prepárate tu negocio para el futuro. Asegura que tu empresa esté <b>lista para nuevos desafíos</b>.</>
        },
        {
            title: "Mejores relaciones",
            description: <><b>Fortalece la confianza</b> con tus clientes y socios comerciales. Construye relaciones más sólidas y duraderas.</>
        },
    ]
}

export const historySection = {
    title: "Historias que conectan",
    description: <>
        Descubre cómo estamos transformando la forma en que emprendedores y empresarios dirigen sus negocios. Estas son las experiencias de líderes que, con nuestro apoyo, <b>han superado obstáculos legales y enfocado su energía en su visión</b>.
    </>,
    historyLeft: {
        name: "Lina Soto",
        occupation: "Médico",
        description: '“ Consultopia me simplifica las tareas legales. Ahora puedo destinar más tiempo a atender a mis pacientes. Me siento más tranquila sabiendo que mi práctica está en orden ”',
        img: "lina.jpg"
    },
    historyRight: {
        name: "Cristhian Acosta",
        occupation: "CEO - Moderno Agency",
        description: '“ Consultopia nos permitió mejorar la relación con nuestros empleados y clientes. Desde que tenemos el app tomamos decisiones mejor informadas. Las alertas del monitor son brutales! ”',
        img: "cris.jpg"
    },
}

export const ourAlliesSection = {
    sectionName: <>Nuestros <b>Aliados</b> </>,
    allies: ["aws", "ccq", "aei", "aje", "datafast"]
}

export const privacySection = {
    title: "Tu privacidad, nuestra prioridad",
    description: <>
        En Consultopia, nos importa profundamente proteger tu privacidad. <br className="block lg:hidden" /> <br className="block lg:hidden" />
        Cuando haces una consulta o subes documentos, <span className="bg-green-text bg-clip-text text-transparent font-bold">solo tú tienes el control de tu información</span>. <br className="block lg:hidden" /><br className="block lg:hidden" />
        Además, contamos con una infraestructura segura y de primer nivel que protege todos tus datos, garantizando que estén siempre resguardados.
    </>,
    button: "Conocer más",
    buttonLink: "#"
}

export const solutionSection = {
    title: "Soluciones para todo tipo de \n negocios",
    solutions: [
        {
            title: "Profesionales",
            description: "Herramientas de asesoría y gestión que facilitan la organización y el cumplimiento normativo en tu área de especialidad.",
            button: "Conocer más",
            buttonLink: "#"
        },
        {
            title: "Emprendedores",
            description: "Acompañamiento para establecer tu negocio con procesos fundamentales para esta etapa. Acelera el crecimiento sostenible de tus ideas empresariales.",
            button: "Conocer más",
            buttonLink: "#"
        },
        {
            title: "Empresarios",
            description: "Soluciones para optimizar la gestión empresarial, asegurar el cumplimiento de regulaciones y mantenerte informado en aspectos clave.",
            button: "Conocer más",
            buttonLink: "#"
        }
    ]
}

export const questionSection = {
    title: "Preguntas frecuentes",
    questions: [
        {
            question: "¿Cómo garantiza Consultopia la confidencialidad de mi información?",
            answer: "En Consultopia, la seguridad y privacidad de tu información son fundamentales. Firmamos acuerdos de confidencialidad y utilizamos protocolos de seguridad avanzados para proteger todos tus datos. Tu información nunca será compartida con terceros sin tu consentimiento expreso.",
        },
        {
            question: "¿Qué métodos de pago aceptan?",
            answer: "Aceptamos pagos con tarjetas de crédito y débito de todas las entidades bancarias principales. Las transacciones se realizan a través de plataformas seguras y encriptadas para garantizar la protección de tus datos financieros.",
        },
        {
            question: "¿Es posible diferir los pagos o contar con opciones de financiamiento?",
            answer: "Sí, ofrecemos opciones flexibles de pago. Puedes diferir el costo de nuestros servicios en cuotas mensuales utilizando tu tarjeta de crédito, facilitando así la inversión en el crecimiento de tu negocio.",
        },
        {
            question: "¿Qué sucede si no estoy satisfecho con los servicios recibidos?",
            answer: "Tu satisfacción es nuestra prioridad. Si no estás completamente satisfecho, nos comprometemos a trabajar contigo hasta que alcances los resultados esperados. Contamos con políticas claras de revisión y ajustes para garantizar tu tranquilidad.",
        },
        {
            question: "¿Puedo confiar en la experiencia y profesionalismo de Consultopia?",
            answer: "Absolutamente. Nuestro equipo está conformado por profesionales con amplia experiencia en consultoría empresarial. Contamos con casos de éxito y testimonios de clientes satisfechos que avalan nuestro compromiso y efectividad.",
        },
        {
            question: "¿Cómo puedo verificar la legitimidad de Consultopia como empresa?",
            answer: "Consultopia está legalmente constituida y cumple con todas las regulaciones vigentes. Puedes encontrar nuestra información fiscal y legal en los registros públicos correspondientes. Estamos siempre dispuestos a proporcionarte cualquier documentación que necesites.",
        },
        {
            question: "¿Ofrecen soporte después de finalizada una consulta?",
            answer: "Sí, creemos en establecer relaciones duraderas con nuestros clientes. Ofrecemos soporte continuo y opciones de seguimiento para asegurarnos de que los resultados se mantengan y evolucionen conforme a tus objetivos.",
        },

    ],
    alertTitle: "¿Tienes otras dudas?",
    alertText: "Escríbenos y podremos resolver cualquier inquietud.",
    alertButton: "Contáctanos",
    alertButtonLink: "#",
}

export const footer = {
    logo: "logo",
    solutions: {
        title: "Soluciones",
        options: [
            { title: "Profesionales", link: "#" },
            { title: "Emprendedores", link: "#" },
            { title: "Empresarios", link: "#" },
        ]
    },
    features: {
        title: "Funcionalidades",
        options: [
            { title: "Monitor IA", link: "#" },
            { title: "Consultoría 3.0", link: "#" },
            { title: "Documentos Smart", link: "#" },
            { title: "Trámites ágiles", link: "#" },
            { title: "Actualización oportuna", link: "#" },
        ]
    },
    security: {
        title: "Seguridad",
        options: [
            { title: "Política de privacidad", link: "#" },
            { title: "Manifiesto", link: "#" },
        ]
    },
    price: {
        title: "Precios",
        options: [
            { title: "Membresías", link: "#" },
            { title: "Pago por uso", link: "#" },
        ]
    },
    button: "Contáctanos",
    buttonLink: "#",
    download: {
        title: "Descarga",
        options: [
            { title: "APP STORE", text: "Descarga del", image: "apple", link: "#" },
            { title: "GOOGLE PLAY", text: "Descarga de", image: "google", link: "#" },
        ]
    },
    socials: [
        { image: "linkedin", link: "#" },
        { image: "instagram", link: "#" },
        { image: "whatsapp", link: "#" },
    ]
}

export const shceduleSection = {
    title: "¿Todavía tienes dudas?",
    text: "Agenda una demostración personalizada con uno de nuestros especialistas y descubre cómo Consultopia puede ayudarte a proteger y hacer crecer tu negocio.",
    button: "Agendar una demostración",
    buttonLink: "#",
}















export const fifthSection = {
    title: "Una modalidad para cada tipo de negocio",
    description: "Opciones adaptadas a cada tipo de empresa o emprendimiento, permitiendo satisfacer sus necesidades y objetivos comerciales.",
    payUse: {
        title: "Paga por uso",
        description: "Solicita tus consultas y procesos de manera gratuita, recibe ofertas y selecciona a tu consultor favorito.",
        img: "payUse",
        btnText: "Empezar"
    },
    membership: {
        title: "Adquiere una membresía",
        description: "Obtén acceso a servicios legales exclusivos y asesoría continua con membresía de nuestro bufete de abogados.",
        img: "membership",
        btnText: "Empezar"
    },
    benefits: [
        {
            description: "Cada membresía incluye un número de consultas GRATIS.",
            black: "GRATIS",
        },
        {
            description: "Recibes hasta el 15% de descuento en todos tus procesos y trámites.",
            black: "15% de descuento",
        },
        {
            description: "Genera automáticamente documentos y contratos para respaldar tu empresa",
            black: "",
        }
    ]
}

export const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
};
export const sliderSettingsMobile = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
};

export const meta = {
    title: 'CONSULTOPIA',
    description: 'Consultoría legal para Negocios del siglo XXI',
    meta: {
        charset: 'utf-8',
        name: {
            robots: 'noindex',
            googlebot: 'noindex'
        }
    }
}

export const customMobile = {
    title: "¡Descarga la aplicación y lleva contigo toda la asesoría legal que necesitas!",
    description: "Descarga la aplicación  para una experiencia única y conveniente. ¡No pierdas ni un momento más, únete a nuestra comunidad móvil!",
    appleLink: "#",
    googleLink: "#"
}