export default function ConsultTypes() {
    const options = ["video", "voice", "text", "call"]
    return (
        <div className='w-full relative flex justify-center items-center'>
            <div className='w-auto flex justify-center items-center gap-5 px-5 py-3 border border-solid border-skeleton rounded-2xl bg-skeleton'>
                {
                    options.map(item =>
                        <div key={item} className='bg-green-gradient180 rounded-full p-[2px]'>
                            <div key={item} className='flex flex-col justify-center items-center text-xs lg:text-base font-semibold lg:font-normal gap-3 lg:gap-0 border border-black w-10 h-10 rounded-full bg-white'>
                                <img src={`/icons/${item}.svg`} alt={item} className='w-5 h-5 opacity-70' />
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    )
}