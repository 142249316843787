import { BrowserRouter, Route, Routes } from "react-router-dom";
import Privacy from "./pages/Privacy";
import App from "./pages/App";
import MobileCustom from "./pages/MobileCustom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Terms from "./pages/Terms";
import { PrimeReactProvider } from 'primereact/api';
import "primereact/resources/themes/lara-light-cyan/theme.css";
import { themePrime } from "./utils/themePrime";
import MonitorAi from "./pages/Producto/MonitorAI";
import NotFound from "./pages/NotFound";
import Consultancy from "./pages/Producto/Consultancy";
import Document from "./pages/Producto/Document";

export default function RoutesApp() {
    return (
        <BrowserRouter basename="/">
            <PrimeReactProvider value={{ pt: themePrime }}>
                <div className="bg-squareMob md:bg-square bg-contain overflow-x-hidden font-spartan flex flex-col min-h-screen">
                    <Header />
                    <main className="flex-grow">
                        <Routes>
                            <Route path="/" element={<App />} />
                            <Route path="/product/monitorai" element={<MonitorAi />} />
                            <Route path="/product/consultancy" element={<Consultancy />} />
                            <Route path="/product/document" element={<Document />} />
                            <Route path="/privacy" element={<Privacy />} />
                            <Route path="/terms" element={<Terms />} />
                            <Route path="/mobile/customer/:id" element={<MobileCustom />} />

                            <Route path="*" element={<NotFound />} />
                        </Routes>
                    </main>
                    <Footer />
                </div>
            </PrimeReactProvider>

        </BrowserRouter>

    )
}