import { updateSection } from '../../../utils/Info';
import ButtonBlackBR from '../../../components/ButtonBlackBR';
import Button from '../../../components/Button';
import SectionHeader from '../../../components/SectionHeader';


export default function UpdateSection() {
    return (
        <section className='w-full px-5 lg:px-10'>
            <SectionHeader sectionName={updateSection.sectionName} sectionDescription={updateSection.sectionDescription} />
            <div className='w-full bg-white bg-opacity-60 flex flex-col justify-center items-center gap-10 border-05 border-black rounded-xl py-10 lg:py-16 mt-5 lg:mt-0'>
                <h2 className='font-bold text-2xl lg:text-3xl tracking-tighter whitespace-pre-line leading-tight lg:leading-snug text-center'>{updateSection.title}</h2>
                <div className='w-5/6 lg:w-3/5 text-center font-light tracking-tighter text-sm lg:text-base'>
                    <p>{updateSection.description}</p>
                </div>
                <div className='w-5/6 md:w-3/5 flex flex-col md:flex-row justify-center items-center gap-5 lg:gap-20'>
                    <a className='w-48 block' href={updateSection.buttonLeftLink}>
                        <Button title={updateSection.buttonLeft} />
                    </a>
                    <a className='w-48 block' href={updateSection.buttonRightLink}>
                        <ButtonBlackBR title={updateSection.buttonRight} />
                    </a>
                </div>
                <div className='w-5/6 md:w-11/12 lg:w-3/4 xl:w-1/2 h-[530px] md:h-72 xl:h-64 grid grid-cols-3 md:grid-cols-6 rounded-2xl gap-5 relative pt-10 md:pt-10'>
                    {
                        updateSection.news.map(item =>
                            <div key={item} className='col-span-1 flex justify-center items-start'>
                                <div key={item} className='w-[80px] md:w-24 h-[80px] md:h-24 flex flex-col justify-center items-center p-1 border border-black rounded-full'>
                                    <img src={`/images/${item}.jpg`} alt={item} className='w-full h-full rounded-full border border-black' />
                                </div>
                            </div>
                        )
                    }
                    <img src="/images/updateTypeMobile1.png" alt="updateType" className='block md:hidden absolute w-full top-16' />
                    <img src="/images/updateTypeMobile2.png" alt="updateType" className='block md:hidden absolute w-full -bottom-5' />
                    <img src="/images/updateType.png" alt="updateType" className='hidden md:block absolute md:w-full xl:w-full md:min-w-[400px] xl:min-w-[670px] -bottom-3 lg:-bottom-5 xl:-bottom-7 left-1/2 -translate-x-1/2' />
                </div>
            </div>
        </section>
    )
}