import DocumentMeta from "react-document-meta";
import { meta } from "../utils/Info";

export default function Terms() {
    return (
        <div className="py-20 px-40">
            <DocumentMeta {...meta} />
            
            <h1 className="w-full text-center text-3xl mb-3 font-semibold">Términos y condiciones</h1>
            <p className="font-light my-5">Al usar nuestros servicios Ud. otorga su consentimiento libre, previo y expreso. Sí Ud. es menor de edad, deberá contar con el consentimiento de su tutor.</p>
            <ol className="!list-decimal text-justify">
                <li className="font-semibold my-10">
                    <h3 className="text-xl">PRIMERO: Condiciones Generales.</h3>
                    <p className="font-light my-5">Al descargar la app CONSULTOPÍA, en adelante "Consultopía", la persona, en adelante el "Usuario", acepta las condiciones que se establecen a continuación, manifestando su total conformidad con éstas, por lo que solicitamos leer con detención los términos y condiciones que rigen el uso de nuestra app.</p>
                </li>
                <li className="font-semibold my-10">
                    <h3 className="text-xl">SEGUNDO: Antecedentes Previos.</h3>
                    <p className="font-light my-5">Consultopía es una sociedad dedicada a ofrecer una variedad de servicios cuando descargas la app. Brindándote múltiples servicios legales en distintas ramas del derecho.</p>
                </li>
                <li className="font-semibold my-10">
                    <h3 className="text-xl">TERCERO: Condiciones de Uso del Sitio Web.</h3>
                    <p className="font-light my-5">El Usuario de nuestra app se compromete a hacer uso de la misma con fines exclusivamente personales. La app Consultopía tiene por fin poner a disposición de los consumidores (i) información relativa a sus peticiones legales, (ii) realizar requerimientos solicitados; y, (iii) facilitar consultas legales. <br /> <br />
                        Queda prohibida la modificación o alteración, en cualquier forma, de los contenidos publicados en la app. El contenido y software de este sitio es propiedad de Consultopía y está protegido bajo las leyes internacionales y nacionales del derecho de autor y propiedad intelectual.  <br /> <br />
                        El Usuario de la app, no podrá hacer uso de la misma con fines ilícitos, que lesionen derechos e intereses de terceros, o que de cualquier forma puedan dañar, inutilizar, deteriorar o impedir la normal utilización de este sitio, los equipos informáticos o los documentos, archivos y toda clase de contenidos almacenados en cualquier equipo informático de Consultopía. La infracción de las condiciones de uso de la app por parte de un Usuario implicará la cancelación de su cuenta y la anulación de servicios por éste. Todo lo anterior se entiende en relación a la expresa reserva de acciones legales tanto penales como civiles para los casos de infracción.
                    </p>
                </li>
                <li className="font-semibold my-10">
                    <h3 className="text-xl">CUARTO: Condiciones de Servicios.</h3>
                    <p className="font-light my-5">Consultopía pone a disposición de sus Usuarios todos sus puntos de servicios, entendiéndose por tales los correspondientes a ofrecer una variedad de funciones cuando descargas la app. Brindándote múltiples servicios legales en distintas ramas del derecho. <br /> <br />
                        Antes de confirmar su descarga realizada por los medios ya establecidos, el Usuario deberá revisar el detalle de su orden en pantalla, esto ya que no está permitido realizar cambios de Consultopía una vez completo el proceso de consulta, de acuerdo a lo establecido en el presente instrumento.  <br /> <br />
                        La transacción quedará sujeta a la verificación por parte de la empresa emisora de la tarjeta bancaria u otro medio de pago elegido por el Usuario. No se considerará como completa una transacción mientras Consultopía no reciba la autorización de la institución financiera respectiva. En caso de que Consultopía no reciba dicha confirmación, se anulará la consulta.
                    </p>
                </li>
                <li className="font-semibold my-10">
                    <h3 className="text-xl">QUINTO: Condiciones de Entrega.</h3>
                    <p className="font-light my-5">Al adquirir las consultas y servicios de nuestra app el Usuario recibirá una notificación a su correo electrónico para verificación del uso y descarga de la app.</p>
                </li>
                <li className="font-semibold my-10">
                    <h3 className="text-xl">SEXTO: Política de Devoluciones.</h3>
                    <p className="font-light my-5">Consultopía declara expresamente que no se realizarán devoluciones una vez finalizado el proceso de servicio. Por esta razón, invitamos a nuestros consumidores a revisar cuidadosamente los datos de su orden antes de confirmar su pedido. La declaración anterior se encuentra conforme con lo establecido en el artículo 3 bis, letra b) de la ley número 19.496. Sera de su exclusiva responsabilidad la revisión de todos los antecedentes proporcionados en el proceso de servicio y de las características de los requerimientos solicitados. <br /> <br />
                        En caso de cambios en la configuración del servicio Consultopía procederá a realizar la rectificación de lo solicitado por los Usuarios autorizados.
                    </p>
                </li>
                <li className="font-semibold my-10">
                    <h3 className="text-xl">SÉPTIMO: Servicio al Cliente.</h3>
                    <p className="font-light my-5">Con el fin de contestar todas las dudas que éstos puedan tener sobre los términos y condiciones y solucionar cualquier problema con los servicios prestados por Consultopía, los consumidores pueden ponerse en contacto con nuestra mesa de ayuda llamando al +593983047585 o escribiendo al email cuentas@consultopia.app Para información sobre los eventos, visite nuestro portal www.consultopia.app <br /> <br />
                        En caso de solicitar devoluciones favor contactarse a los mismos teléfonos y datos entregados en la primera parte de la presente cláusula. Consultopía sin perjuicio de ello, siempre responderá dudas y tratará de prestar un servicio de calidad a sus Clientes, para que la experiencia de servicio sea la mejor.
                    </p>
                </li>
                <li className="font-semibold my-10">
                    <h3 className="text-xl">OCTAVO: Almacenamiento de información.</h3>
                    <p className="font-light my-5">Consultopía realiza un proceso de tratamiento de datos con el fin de celebrar con el Usuario un contrato de servicios de las diferentes asistencias que se ofrecen en la app que el Usuario libremente descarga. <br /> <br />
                        Para realizar la transacción señalada, se almacenan datos de carácter personal del Usuario, previo consentimiento de éste, relativos a su nombre completo, cédula nacional de identidad, domicilio, teléfono, fecha de nacimiento, género y dirección de correo electrónico.
                    </p>
                </li>
                <li className="font-semibold my-10">
                    <h3 className="text-xl">NOVENO: Uso de información almacenada.</h3>
                    <p className="font-light my-5">Consultopía utiliza la información del Usuario, previo consentimiento de éste, para su almacenamiento, con el fin de realizar los trámites correspondientes a la consultoría legal. Asimismo, el uso de los Datos Personales Registrados en la app tiene como única finalidad gestionar la labor de requerimientos solicitados por los usuarios.
                    </p>
                </li>
                <li className="font-semibold my-10">
                    <h3 className="text-xl">DÉCIMO: Procedimiento de modificación, eliminación o cancelación de datos almacenados.</h3>
                    <p className="font-light my-5">A requerimiento del Usuario, se procederá a eliminar los datos caducos y aquellos cuya exactitud no pueda ser establecida o cuya vigencia sea dudosa. <br /> <br />
                        Para iniciar el procedimiento de modificación o eliminación de los datos, el Usuario deberá enviar un correo electrónico a la dirección cuentas@consultopia.app , solicitando la modificación o eliminación de los datos y explicando la razón de la solicitud.  <br /> <br />
                        No obstante, los procedimientos descritos anteriormente, el Usuario podrá eliminar, en cualquier momento, su cuenta y los datos que se contengan en ella desde la app.
                    </p>
                </li>
                <li className="font-semibold my-10">
                    <h3 className="text-xl">UNDÉCIMO: Política de Privacidad.</h3>
                    <p className="font-light my-5">Consultopía utiliza la tecnología más avanzada para la protección de la información proporcionada por los Usuarios de los Servicios. <br /> <br />
                        El uso de los Datos Personales Registrados en la app tiene como única finalidad realizar los requerimientos que el usuario solicita. Dichos datos no serán traspasados a terceros, salvo instituciones o empresas relacionadas con la jurisdicción y competencia propias de trámites legales. <br /> <br />
                        En razón de lo anterior se han implementado todas las medidas de seguridad para proteger los datos personales requeridos a los Usuarios que hacen uso de la app. <br /> <br />
                        Se podrán generar bases de datos con la información proporcionada por el usuario, las que se utilizarán por Consultopía exclusivamente para fines legales. <br /> <br />
                        Toda información solicitada al Usurario, deberá ser proveída por éste de manera voluntaria.
                    </p>
                </li>
                <li className="font-semibold my-10">
                    <h3 className="text-xl">DUODÉCIMO: Uso de cookies y seguridad.</h3>
                    <p className="font-light my-5">El servidor de datos por parte de Consultopía contempla el envío de datos al navegador del Usuario para que se almacenen en el disco duro de su computadora y así permitir identificar las computadoras utilizados por los Usuarios al tener acceso a nuestro Portal, este proceso es conocido como el uso de "cookies" o "direcciones de IP". Mediante el uso de "cookies" o "direcciones de IP" Consultopía es capaz de ofrecer un servicio más personalizado a sus Usuarios.</p>
                </li>
                <li className="font-semibold my-10">
                    <h3 className="text-xl">DÉCIMO TERCERO: Exención de Responsabilidades.</h3>
                    <p className="font-light my-5">A pesar del constante esfuerzo de Consultopía por entregar a sus usuarios la mejor experiencia y calidad posible, la empresa no se obliga a que el sitio esté libre de errores e interrupciones. En relación a lo anterior, no se garantiza la ausencia de virus ni de otros elementos en los contenidos que puedan producir alteraciones en su sistema informático (software y hardware) o en los documentos electrónicos y ficheros almacenados en su sistema informático. Por lo anterior, Consultopía se exime, con toda la extensión permitida por el ordenamiento jurídico, de cualquier responsabilidad por los daños y perjuicios de toda naturaleza que puedan deberse a la presencia de virus o a la presencia de otros elementos en los contenidos que puedan producir alteraciones en el sistema informático, documentos electrónicos o ficheros de los usuarios.</p>
                </li>
            </ol>
        </div >
    )
}