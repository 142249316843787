import { documentSection } from '../../../utils/Info';
import ButtonBlackBR from '../../../components/ButtonBlackBR';
import Button from '../../../components/Button';
import SectionHeader from '../../../components/SectionHeader';


export default function DocumentSection() {
    return (
        <section className='w-full px-5 lg:px-10'>
            <SectionHeader sectionName={documentSection.sectionName} sectionDescription={documentSection.sectionDescription} />
            <div className='w-full bg-white bg-opacity-60 flex flex-col justify-end items-center gap-10 border-05 border-black rounded-xl pt-16'>
                <h2 className='font-bold text-2xl lg:text-3xl tracking-tighter whitespace-pre-line leading-tight lg:leading-snug text-center'>{documentSection.title}</h2>
                <div className='w-5/6 lg:w-3/5 text-center font-light tracking-tighter text-sm lg:text-base'>
                    <p>{documentSection.description}</p>
                </div>
                <div className='w-5/6 lg:w-3/5 flex flex-col md:flex-row justify-center items-center gap-5 lg:gap-20'>
                    <a className='w-48 block' href={documentSection.buttonLeftLink}>
                        <Button title={documentSection.buttonLeft} />
                    </a>
                    <a className='w-48 block' href={documentSection.buttonRightLink}>
                        <ButtonBlackBR title={documentSection.buttonRight} />
                    </a>
                </div>
                <div className='w-full relative'>
                    <img src={`/images/${documentSection.imageBottom}`} alt={documentSection.imageBottom} className='w-full md:w-[45%] absolute -bottom-24 md:-bottom-20 lg:-bottom-28 xl:-bottom-36 left-0 md:left-[27%] -translate-y-1/2 -translate-x-1/2 animate-person z-20' />
                    <img src={`/images/${documentSection.imageCenter}`} alt={documentSection.imageCenter} className='w-4/5 md:w-2/5 mx-auto' />
                </div>
            </div>
        </section>
    )
}