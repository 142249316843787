import { homeSection } from '../../../utils/Info';
import ButtonBlackBR from '../../../components/ButtonBlackBR';
import ButtonWhite from '../../../components/ButtonWhite';


export default function HomeSection() {
    return (
        <section className='min-h-[calc(100vh-7rem)] w-full flex flex-col justify-center md:justify-start xl:justify-center items-center gap-10 py-5 md:pt-20 xl:pt-5'>
            <h1 className='md:w-5/6 lg:w-full font-extrabold text-3xl md:text-4xl lg:text-5xl whitespace-pre-line leading-tight md:leading-snug lg:leading-snug text-center tracking-tighter'>{homeSection.title}</h1>
            <div className='w-5/6 text-center font-light tracking-tighter md:mt-10 lg:mt-20 xl:m-0'>
                <p className='md:font-bold md:mb-5 lg:mb-0'>{homeSection.subtitle}</p>
                <p className='hidden md:block'>{homeSection.description}</p>
            </div>
            <div className='w-full flex flex-col md:flex-row justify-center items-center gap-5 lg:gap-24 relative md:my-10 lg:my-20 xl:m-0'>
                <a className='w-48 block' href={homeSection.buttonLeftLink}>
                    <ButtonBlackBR title={homeSection.buttonLeft} />
                </a>
                <a className='w-48 block' href={homeSection.buttonLeftLink}>
                    <ButtonWhite title={homeSection.buttonRight} />
                </a>
            </div>
            <div className='w-full md:w-5/6 lg:w-2/3 flex justify-center items-center relative'>
                <img src={`/gif/${homeSection.gif}`} alt={homeSection.gif} className='w-56 relative z-10' />
                <div className='hidden md:flex w-full absolute py-8 bg-white border-05 border-black rounded-xl md:justify-between lg:justify-center items-center font-thin text-lg mb-10 md:pl-16 md:pr-10 lg:pr-0 lg:pl-16 xl:pl-10 pr-5 md:gap-0 lg:gap-36'>
                    <p>
                        {homeSection.textLeft} <span className='font-bold md:text-3xl lg:text-4xl font-lora'>{homeSection.textLeftBold}</span>
                    </p>
                    <p>
                        {homeSection.textRight} <span className='font-bold md:text-3xl lg:text-4xl font-lora'>{homeSection.textRightBold}</span>
                    </p>
                </div>
            </div>
        </section>
    )
}