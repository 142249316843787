function ButtonBlackBR({ title }: { title: any }) {
    return (
        <div className="button-hover-bottomRight relative">
            <button className='bottomRight-bottom relative z-10 h-11 w-full font-semibold text-sm border border-solid border-black rounded-[10px] bg-black text-white'>
                {title}
            </button>
            <span className="bottomRight-top absolute bg-green-gradient90 h-11 w-full top-[6px] left-[6px] rounded-[10px]"></span>
        </div>
    );
}

export default ButtonBlackBR;
