import { procedureSection } from '../../../utils/Info';
import ButtonBlackBR from '../../../components/ButtonBlackBR';
import Button from '../../../components/Button';
import SectionHeader from '../../../components/SectionHeader';
import { useEffect, useRef } from 'react';


export default function ProcedureSection() {
    const phoneSection = useRef<HTMLDivElement | null>(null);
    const text_one = useRef<HTMLDivElement | null>(null);
    const text_two = useRef<HTMLDivElement | null>(null);
    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    console.log('entry.isIntersecting', entry.isIntersecting)
                    if (entry.intersectionRatio >= 0.3) {
                        text_two.current?.classList.add("animate-consult-slide-right");
                        text_two.current?.classList.remove("hidden");
                    }
                    if (entry.intersectionRatio >= 0.1) {
                        text_one.current?.classList.add("animate-consult-slide-right");
                        text_one.current?.classList.remove("hidden");
                    }
                });
            },
            {
                threshold: [0.1, 0.3], // Se activan en los umbrales 30% y 50%
            }
            // { threshold: 0.1 } // Se activa cuando el 50% del elemento es visible
        );

        if (phoneSection.current) {
            observer.observe(phoneSection.current);
        }

        return () => {
            if (phoneSection.current) {
                observer.unobserve(phoneSection.current);
            }
        };
    }, [])
    return (
        <section ref={phoneSection} className='w-full px-5 lg:px-10'>
            <div className='pt-20 lg:pt-0'>
                <SectionHeader sectionName={procedureSection.sectionName} sectionDescription={procedureSection.sectionDescription} />
            </div>
            <div className='w-full grid grid-cols-12 pb-10'>
                <div className='col-span-12 md:col-span-6 flex justify-center items-center relative tracking-tighter py-10 lg:py-0'>
                    <img src="/images/phoneProcedures.png" alt="phone" className='hidden lg:block w-1/2' />
                    <img src="/images/phoneProceduresMobile.png" alt="phone" className='block lg:hidden w-2/3' />
                    <p ref={text_one} className='bg-white border border-black p-3 xl:p-5 text-sm xl:text-base rounded-xl absolute top-[20%] xl:top-[15%] left-0 hidden'>{procedureSection.phoneCard[0]}</p>
                    <p ref={text_two} className='bg-white border border-black p-3 xl:p-5 text-sm xl:text-base rounded-xl absolute top-[75%] right-0 hidden'>{procedureSection.phoneCard[1]}</p>
                </div>
                <div className='col-span-12 md:col-span-6 flex flex-col justify-center items-center gap-10'>
                    <h2 className='font-bold text-2xl lg:text-3xl tracking-tighter whitespace-pre-line leading-tight lg:leading-snug text-center'>{procedureSection.title}</h2>
                    <div className='w-5/6 lg:w-full text-center font-light tracking-tighter text-sm lg:text-base'>
                        <p>{procedureSection.description}</p>
                    </div>
                    <div className='w-full flex flex-col md:flex-row justify-center items-center gap-5 lg:gap-24 relative mb-20 lg:mb-52'>
                        <a className='w-48 block' href={procedureSection.buttonLeftLink}>
                            <Button title={procedureSection.buttonLeft} />
                        </a>
                        <a className='w-48 block' href={procedureSection.buttonRightLink}>
                            <ButtonBlackBR title={procedureSection.buttonRight} />
                        </a>
                        {/* <img src="/images/bear.png" alt="bear" className='w-40 md:w-80 absolute top-16 md:-top-10 z-20 -rotate-3 left-1/2 lg:left-[45%] -translate-x-1/2 pointer-events-none animate-bear' /> */}
                        <img src="/images/bear.png" alt="bear" className='w-40 md:w-80 absolute top-16 md:-top-[45px] left-[30%] md:left-[10%] lg:left-[15%] xl:left-[25%] -translate-x-1/2 pointer-events-none animate-bear z-20' />
                    </div>
                </div>
            </div>
        </section>
    )
}