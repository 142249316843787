import { benefitsSection } from '../../../utils/Info';

export default function BenefitsSection() {
    return (
        <section className='w-full px-5 lg:px-10'>
            <div className='h-36 flex justify-between items-center tracking-tighter'>
                <h2 className='font-bold text-2xl lg:text-3xl tracking-tighter whitespace-pre-line leading-tight lg:leading-snug text-center w-full'>{benefitsSection.title}</h2>
            </div>
            <div className='w-full bg-black rounded-xl p-5 lg:p-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 grid-rows-2 tracking-tighter'>
                {
                    benefitsSection.benefits.map((item, index) =>
                        <div key={item.title}>
                            <div className={`hidden lg:block ${index === 0 && "bg-green-table-left"} ${index === 2 && "bg-green-table-right"} ${index === 1 ? "bg-green-table-top" : index === 4 && "bg-green-table-bottom"} ${[0, 1, 2].includes(index) && "pb-07"} ${[1, 4].includes(index) && "px-05"}`}>
                                <div className='w-full h-full text-white flex flex-col justify-center items-center px-10 py-16 bg-black'>
                                    <p className='w-full text-center font-bold text-base lg:text-xl mb-5 bg-green-text bg-clip-text text-transparent h-14'>{item.title}</p>
                                    <p className='w-full text-center text-sm h-28'>{item.description}</p>
                                </div>
                            </div>

                            <div className={`hidden md:block lg:hidden ${[0, 2, 4].includes(index) && "bg-green-table-left"} ${index === 1 && "bg-green-table-tablet-top"} ${index === 5 && "bg-green-table-tablet-bottom"} ${index === 3 && "bg-green-table-right"}  ${![4, 5].includes(index) && "pb-07"} ${[1, 3, 5].includes(index) && "pl-05"}`}>
                                <div className='w-full h-full text-white flex flex-col justify-center items-center px-10 py-10 bg-black'>
                                    <p className='w-full text-center font-bold text-base md:text-xl mb-5 bg-green-text bg-clip-text text-transparent h-14'>{item.title}</p>
                                    <p className='w-full text-center text-sm h-28'>{item.description}</p>
                                </div>
                            </div>

                            <div className={`block md:hidden bg-green-table-mobile ${index !== 5 && "pb-07"}`}>
                                <div className='w-full h-full text-white flex flex-col justify-center items-center px-5 py-7 bg-black'>
                                    <p className='w-full text-center font-bold text-base lg:text-xl mb-5 bg-green-text bg-clip-text text-transparent'>{item.title}</p>
                                    <p className='w-full text-center text-sm h-24'>{item.description}</p>
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
        </section>
    )
}