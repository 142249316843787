import { ourAlliesSection } from '../../../utils/Info';

export default function OurAlliesSection() {
    return (
        <section className='w-full px-5 lg:px-10 tracking-tighter'>
            <div className='h-28 flex justify-between items-center font-lora'>
                <p className='text-xl'>{ourAlliesSection.sectionName}</p>
            </div>
            <div className='w-full py-7 bg-white bg-opacity-60 border-05 border-black rounded-xl flex flex-wrap lg:flex-nowrap justify-center items-center gap-10'>
                {
                    ourAlliesSection.allies.map(item =>
                        <img key={item} src={`/images/${item}.png`} alt={item} className='h-8 lg:h-10' />
                    )
                }
            </div>
        </section>
    )
}