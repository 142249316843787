import DocumentMeta from "react-document-meta";
import { meta } from "../../utils/Info";
import { documents } from "../../utils/InfoProduct";
import Section from "./components/Section";
import CardOption from "./components/CardOption";
import Schedule from "../../components/Schedule";

export default function Document() {
    return (
        <div className='w-full max-w-screen-2xl mx-auto flex flex-col justify-center items-center overflow-hidden px-5 lg:px-10'>
            <DocumentMeta {...meta} />
            <Section text={documents.section} />
            <img src={`/icons/${documents.title}.svg`} alt={documents.title} className="pt-16 lg:pt-5 pb-10" />
            <div className="w-full lg:w-6/12 tracking-normal text-lg leading-tight space-y-10">
                <p className="font-bold text-center">{documents.subtitle}</p>
                <p className="text-center text-sm">{documents.description}</p>
            </div>
            <div className="w-full relative py-10">
                <img src="/gif/DavidAi.gif" alt="DavidAi" className="w-60 mx-auto relative z-10" />
                <img src="/icons/separator.svg" alt="separator" className="w-full absolute top-1/2 left-1/2 -translate-x-1/2" />
            </div>
            <div className="w-full pb-10 space-y-32 lg:space-y-16">
                {
                    documents.options.map(option => <CardOption option={option} product={documents.title} />)
                }
            </div>
            <Schedule />
        </div>
    )
}